import { useCallback, useState } from 'react';

import { useSelectedUsers } from '../../../InviteUsersModalButton/hooks/useSelectedUsers';

import { CheckableUsersListItemUserType } from '../../../InviteUsersModalButton/components/InviteUsersModalBody/components/CheckableUsersListItem';

import { UserID } from '../../../../../users/usersTypes';

interface SelectClientModalButtonProps {
  onAddClient: (ids: UserID[]) => void;
}

function useSelectClientModalButton({
  onAddClient
}: SelectClientModalButtonProps) {
  const [fullName, setFullName] = useState<string>('');

  const {
    selectedUsers,
    selectedUserIds,
    handleSelectUser,
    handleDeselectUser,
    handleDeselectAllUsers
  } = useSelectedUsers();

  const handleCheckUser = useCallback<
    (user: CheckableUsersListItemUserType, checked: boolean) => void
  >(
    (user: CheckableUsersListItemUserType, checked: boolean) =>
      checked ? handleSelectUser(user) : handleDeselectUser(user),
    [handleSelectUser, handleDeselectUser]
  );

  const handleCheckAllUsers = useCallback<
    (users: CheckableUsersListItemUserType[], checked: boolean) => void
  >(
    (users: CheckableUsersListItemUserType[], checked: boolean) =>
      checked
        ? users?.forEach((user) => handleSelectUser(user))
        : users?.forEach((user) => handleDeselectUser(user)),
    [handleSelectUser, handleDeselectUser]
  );

  const handleChangeFullName = useCallback<
    (value: { fullName: string }) => void
  >((value) => setFullName(value?.fullName), [setFullName]);

  const handleAfterSubmit = useCallback<() => void>(() => {
    onAddClient(selectedUserIds);
    handleDeselectAllUsers();
  }, [handleDeselectAllUsers, onAddClient, selectedUserIds]);

  const handleOpen = useCallback<() => void>(
    () => handleChangeFullName(null),
    [handleChangeFullName]
  );

  return {
    handleDeselectAllUsers,
    handleDeselectUser,
    handleSelectUser,
    handleChangeFullName,
    handleCheckUser,
    handleCheckAllUsers,
    handleAfterSubmit,
    handleOpen,
    selectedUserIds,
    selectedUsers,
    fullName
  };
}

export default useSelectClientModalButton;
