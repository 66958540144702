import { useEffect, useMemo, useRef } from 'react';
import * as yup from 'yup';
import { AnyObjectSchema } from 'yup';

import { MultiSelectDataType } from '../../../../../../../helpers/MultiSelect/types';
import { ProjectUUID } from '../../../../../../projects/projectsTypes';
import { TaskUUID } from '../../../../../../tasks/tasksTypes';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';

import {
  SelectProjectAndTaskFormData,
  SelectProjectAndTaskFormOnSubmitAction,
  SelectProjectAndTaskFormFields
} from '../../SelectProjectAndTaskForm.types';

import { formsErrors } from '../../../../../../../locales/keys';

interface SelectProjectAndTaskFormOptions {
  onSubmit: SelectProjectAndTaskFormOnSubmitAction;
  selectedTaskEmptyValue?: MultiSelectDataType | null;
  withoutProject?: boolean;
}

function useSelectProjectAndTaskForm({
  onSubmit,
  selectedTaskEmptyValue = null,
  withoutProject = false
}: SelectProjectAndTaskFormOptions) {
  const selectProjectAndTaskValidationSchema = useMemo<AnyObjectSchema>(
    () =>
      yup.object({
        [SelectProjectAndTaskFormFields.SELECTED_PROJECT]: withoutProject
          ? undefined
          : yup.object().nullable().required(formsErrors.required),
        [SelectProjectAndTaskFormFields.SELECTED_TASK]: yup
          .object()
          .nullable()
          .required(formsErrors.required)
      }),
    [withoutProject]
  );

  const submitResultsToTaskFormDefaultValues =
    useMemo<SelectProjectAndTaskFormData>(() => {
      return {
        selectedProject: null,
        selectedTask: selectedTaskEmptyValue
      };
    }, [selectedTaskEmptyValue]);

  const {
    handleSubmitReactHookForm,
    register,
    errors,
    control,
    resetForm,
    watch,
    setValue
  } = useReactHookForm<SelectProjectAndTaskFormData>({
    defaultValues: submitResultsToTaskFormDefaultValues,
    isModalForm: true,
    schema: selectProjectAndTaskValidationSchema
  });

  // ignore because of "Type instantiation is excessively deep and possibly infinite." error
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const watchSelectedProjectUuid = watch(
    SelectProjectAndTaskFormFields.SELECTED_PROJECT as keyof SelectProjectAndTaskFormData
  )?.value as ProjectUUID;

  const prevSelectedProjectUuidRef = useRef<ProjectUUID | null>(null);
  useEffect(() => {
    if (
      watchSelectedProjectUuid &&
      prevSelectedProjectUuidRef.current !== watchSelectedProjectUuid
    ) {
      setValue(
        SelectProjectAndTaskFormFields.SELECTED_TASK as keyof SelectProjectAndTaskFormData,
        selectedTaskEmptyValue
      );
      prevSelectedProjectUuidRef.current = watchSelectedProjectUuid;
    }
  }, [watchSelectedProjectUuid, setValue, selectedTaskEmptyValue]);

  return {
    control,
    resetForm,
    handleSelectProjectAndTask: handleSubmitReactHookForm({
      onSubmit: (data: SelectProjectAndTaskFormData) =>
        onSubmit({
          projectUuid: data.selectedProject?.value as ProjectUUID,
          taskUuid:
            !data.selectedTask ||
            data.selectedTask?.value === selectedTaskEmptyValue?.value
              ? null
              : (data.selectedTask?.value as TaskUUID)
        })
    }),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    registerProjectUuid: register(
      SelectProjectAndTaskFormFields.SELECTED_PROJECT
    ),
    registerTaskUuid: register(SelectProjectAndTaskFormFields.SELECTED_TASK),
    validationErrors: {
      selectedProjectValidationError: errors?.selectedProject?.['message'],
      selectedTaskValidationError: errors?.selectedTask?.['message']
    },
    watchSelectedProjectUuid,
    selectedTaskEmptyValue
  };
}

export default useSelectProjectAndTaskForm;
