import { useRef, useEffect, useCallback } from 'react';
import throttle from 'lodash/throttle';
import isNumber from 'lodash/isNumber';

import { IsLoading, ErrorMessage } from '../../../../../types';

import { calcDistanceToViewport } from '../../utils/calcDistanceToViewport';

import { FetchMoreInfiniteButtonHelperPlacement } from '../../FetchMoreInfiniteButtonHelper.types';

interface FetchMoreInfiniteButtonHelperOptions {
  distanceToViewport: number;
  errorMessage: ErrorMessage;
  isLoading?: IsLoading;
  hasNextPage: boolean;
  placement: FetchMoreInfiniteButtonHelperPlacement;
  onFetchMore: () => void;
}

function useFetchMoreInfiniteButtonHelper({
  distanceToViewport,
  isLoading,
  hasNextPage,
  errorMessage,
  placement,
  onFetchMore
}: FetchMoreInfiniteButtonHelperOptions) {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const canLoadMore: boolean = !isLoading && hasNextPage && !errorMessage;
  const canLoadMoreRef = useRef<boolean>(canLoadMore);
  canLoadMoreRef.current = canLoadMore;

  const handleScroll = useCallback(() => {
    if (!buttonRef.current || !canLoadMoreRef.current) {
      return;
    }
    const currentDistance = calcDistanceToViewport(
      buttonRef.current,
      placement
    );

    if (isNumber(currentDistance) && currentDistance < distanceToViewport) {
      onFetchMore();
    }
  }, [buttonRef, canLoadMoreRef, placement, distanceToViewport, onFetchMore]);

  useEffect(() => {
    if (!canLoadMore) {
      return;
    }

    const handler = throttle(handleScroll, 100);

    window.addEventListener('scroll', handler, true);
    return () => {
      window.removeEventListener('scroll', handler, true);
      handler.cancel();
    };
  }, [canLoadMore, handleScroll]);

  return { buttonRef };
}

export default useFetchMoreInfiniteButtonHelper;
