import { UpdateItemCacheKeys } from '../../../../types';

import { ImageUUID, UpdateImageGqlQuery } from '../../imagesTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface RecreateImageVersionsOptions {
  query: UpdateImageGqlQuery;
  cacheKeys?: UpdateItemCacheKeys;
}

export interface RecreateImageVersionsInput {
  clientMutationId?: string;
  uuid: ImageUUID;
  async: boolean;
  versions: string[];
}

export interface RecreateImageVersionsError {
  fullMessages: string[] | null;
}

export interface RecreateImageVersionsResponse {
  recreateImageVersions: {
    status: string;
    errors: RecreateImageVersionsError;
  };
}

export const recreateImageVersionsAction = 'recreateImageVersions';

function useRecreateImageVersions({
  query,
  cacheKeys
}: RecreateImageVersionsOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    RecreateImageVersionsInput,
    RecreateImageVersionsResponse,
    RecreateImageVersionsError,
    undefined
  >({
    action: recreateImageVersionsAction,
    cacheKeys,
    query
  });

  return {
    recreateImageVersionsData: updateQueryData,
    recreateImageVersionsError: updateQueryError,
    recreateImageVersionsLoading: updateQueryLoading,
    recreateImageVersionsErrorMessage: updateQueryErrorMessage,
    recreateImageVersions: updateQuery,
    recreateImageVersionsReset: updateQueryReset
  };
}

export default useRecreateImageVersions;
