import { FetchMoreInfiniteButtonHelperPlacement } from '../../FetchMoreInfiniteButtonHelper.types';

function calcDistanceToViewport<T extends HTMLElement>(
  element: T,
  placement: FetchMoreInfiniteButtonHelperPlacement
): number | null {
  if (placement === FetchMoreInfiniteButtonHelperPlacement.BOTTOM) {
    return (
      element.getBoundingClientRect().top -
      document.documentElement.clientHeight
    );
  }

  if (placement === FetchMoreInfiniteButtonHelperPlacement.TOP) {
    return -element.getBoundingClientRect().bottom;
  }

  return null;
}

export default calcDistanceToViewport;
