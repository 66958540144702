import { Fragment, ReactNode } from 'react';
import range from 'lodash/range';
import Skeleton from 'react-loading-skeleton';

import 'react-loading-skeleton/dist/skeleton.css';

import { ClassName } from '../../types';

interface LoadingGridSkeletonProps {
  children?: ReactNode;
  className?: ClassName;
  itemClassName?: ClassName;
  loaded?: boolean;
  itemsCount?: number;
}

function LoadingGridSkeleton({
  children,
  className,
  itemClassName,
  loaded,
  itemsCount = 16
}: LoadingGridSkeletonProps) {
  if (loaded) return <Fragment>{children}</Fragment>;

  return (
    <div
      className={
        className ||
        'px-4 grid grid-cols-auto-fill grid-cell-min-48 xl:grid-cell-min-52 2xl:grid-cell-min-64 3xl:grid-cell-min-72 gap-4 xl:gap-8 2xl:gap-12'
      }
    >
      {range(itemsCount).map((key) => (
        <div
          key={key}
          className={
            itemClassName ||
            'p-1 w-80 border max-w-full mx-auto relative rounded-md'
          }
        >
          <div className="rounded-md aspect-w-1 aspect-h-1">
            <Skeleton height="100%" containerClassName="flex" />
          </div>
          <div className="p-2">
            <Skeleton width={200} />
            <Skeleton width={160} />
          </div>
          <div className="p-2 flex justify-between items-center">
            <Skeleton width={100} height={30} />
            <Skeleton width={30} height={30} />
          </div>
        </div>
      ))}
    </div>
  );
}

export default LoadingGridSkeleton;
