import React from 'react';
import isEmpty from 'lodash/isEmpty';

import {
  CheckboxAlphabeticalGroupItemsType,
  CheckboxAlphabeticalGroupOnRemove
} from '../../CheckboxAlphabeticalGroup.types';

import { CheckboxAlphabeticalGroupSelectedItem } from '../CheckboxAlphabeticalGroupSelectedItem';

interface CheckboxAlphabeticalGroupSelectedProps {
  selectedItems: CheckboxAlphabeticalGroupItemsType;
  onRemove: CheckboxAlphabeticalGroupOnRemove;
}

function CheckboxAlphabeticalGroupSelected({
  selectedItems,
  onRemove
}: CheckboxAlphabeticalGroupSelectedProps) {
  if (isEmpty(selectedItems)) {
    return null;
  }

  return (
    <div className="flex flex-wrap -m-0.5">
      {selectedItems.map(({ id, label }) => (
        <CheckboxAlphabeticalGroupSelectedItem
          key={id}
          id={id}
          label={label}
          onRemove={onRemove}
        />
      ))}
    </div>
  );
}

export default CheckboxAlphabeticalGroupSelected;
