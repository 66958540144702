import React, { useCallback } from 'react';
import compact from 'lodash/compact';
import concat from 'lodash/concat';
import filter from 'lodash/filter';
import toString from 'lodash/toString';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';

import { IsDisabled } from '../../../types';

import { UserID } from '../../../main/users/usersTypes';

import { SelectClientModalButton } from '../../../main/common/modalButtons/SelectClientModalButton';
import { UsersListPopoverFieldList } from '../../FormFields/UsersListPopoverField/components/UsersListPopoverFieldList';

import { useUsers } from '../../../main/users/hooks/useUsers';

import { categoriesKeys } from '../../../locales/keys';

import {
  FetchItemTeamUsersQueryResponse,
  FETCH_ITEM_TEAM_USERS_QUERY
} from '../../FormFields/UsersListPopoverField/components/UsersListPopoverFieldList/queries/fetchItemTeamUsers.query';

import { IconsEnum } from '../../../assets/icons/types';

import { UserCache } from '../../../main/users/UserCache';

interface ClientsSelectFilterProps {
  onChange: (ids: UserID[]) => void;
  value: UserID[];
  disabled?: IsDisabled;
}

function ClientsSelectFilter({
  onChange,
  value,
  disabled
}: ClientsSelectFilterProps) {
  const {
    users,
    usersFetched,
    usersFetchingNextPage,
    hasNextUsersPage,
    filterUsers,
    loadMoreUsers
  } = useUsers<FetchItemTeamUsersQueryResponse>({
    cacheKey: UserCache.clientsFilterCacheKey(),
    query: FETCH_ITEM_TEAM_USERS_QUERY,
    initialFilters: {
      id: { in: value }
    },
    options: {
      enabled: !!value,
      enabledPlaceholder: !!value
    }
  });

  const handleRemoveClient = useCallback<(id: UserID) => void>(
    (id: UserID) => {
      const filteredClientIds = filter(
        value,
        (v) => toString(v) !== toString(id)
      );
      filterUsers({
        id: isEmpty(filteredClientIds) ? undefined : { in: filteredClientIds }
      });
      onChange(isEmpty(filteredClientIds) ? undefined : filteredClientIds);
    },
    [filterUsers, onChange, value]
  );

  const handleAddClient = useCallback<(ids: UserID[]) => void>(
    (ids: UserID[]) => {
      const filteredClientIds = compact(concat(value, ids));
      filterUsers({
        id: isEmpty(filteredClientIds) ? undefined : { in: filteredClientIds }
      });
      onChange(filteredClientIds);
    },
    [filterUsers, onChange, value]
  );

  return (
    <div>
      <div className="-mx-2">
        <SelectClientModalButton
          onAddClient={handleAddClient}
          i18nText={categoriesKeys.client.select}
          className="py-2 pl-2 pr-4 space-x-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0 w-full"
          icon={IconsEnum.PLUS_SOLID}
          initialUserUuids={
            size(value) > 0 ? users?.map((user) => user?.uuid) : null
          }
          disabled={disabled}
        />
      </div>

      <UsersListPopoverFieldList
        onRemoveUser={handleRemoveClient}
        users={users}
        usersFetched={usersFetched}
        value={value}
        usersFetchingNextPage={usersFetchingNextPage}
        hasNextUsersPage={hasNextUsersPage}
        handleLoadMoreUsers={loadMoreUsers}
        listClassName="-mx-2 mt-2 space-y-1"
        className="none"
        disabled={disabled}
      />
    </div>
  );
}

export default ClientsSelectFilter;
