import React, { useCallback } from 'react';

import { IconsEnum } from '../../../../../assets/icons/types';
import { UserID } from '../../../../../main/users/usersTypes';
import { UsersListFieldListItemUser } from './UsersListFieldListItem.types';

import { PureIconButtonHelper } from '../../../../buttons/PureIconButtonHelper';
import { UserAvatarLink } from '../../../../../main/common/helpers/UserAvatarLink';

interface UsersListFieldListItemProps {
  onRemove: (id: UserID) => void;
  user: UsersListFieldListItemUser;
}

function UsersListFieldListItem({
  onRemove,
  user
}: UsersListFieldListItemProps) {
  const handleRemoveUser = useCallback(
    () => onRemove(user.id),
    [onRemove, user.id]
  );

  return (
    <li className="group p-2 -mx-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-850">
      <div className="flex items-center space-x-4">
        <div className="flex-shrink-0">
          <UserAvatarLink avatarType="main" user={user} />
        </div>
        <div className="flex-1 min-w-0">
          <p className="text-sm font-medium text-gray-900 dark:text-gray-200 truncate">
            {user.fullName}
          </p>
          <p className="text-sm text-gray-500 dark:text-gray-400 truncate">
            {user.financeRoleName}
          </p>
        </div>
        <div>
          <PureIconButtonHelper
            className="py-2 pl-2 pr-2 rounded-full inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:outline-none focus:ring-2 focus:ring-gray-900 dark:focus:ring-gray-100 hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0 opacity-0 group-hover:opacity-100 focus:opacity-100"
            blurOnMouseLeave
            icon={IconsEnum.X_OUTLINE}
            onClick={handleRemoveUser}
          />
        </div>
      </div>
    </li>
  );
}

export default UsersListFieldListItem;
