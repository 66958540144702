import { IconsEnum } from '../../../../assets/icons/types';

import { useTranslate } from '../../../../common/hooks/useTranslate';

import { PureButtonHelper } from '../../../../helpers/buttons/PureButtonHelper';
import { ConfirmModalButton } from '../../../../helpers/buttons/ConfirmModalButton';

import { TooltipPlacement } from '../../../../helpers/tooltips/tooltipsConstants';

import { stringsKeys, words } from '../../../../locales/keys';

interface SelectedLibraryItemsFloatingCounterProps {
  itemsCount: number;
  clearLoading?: boolean;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onClear: () => void;
}

function SelectedLibraryItemsFloatingCounter({
  itemsCount,
  clearLoading,
  isOpen,
  onOpen,
  onClose,
  onClear
}: SelectedLibraryItemsFloatingCounterProps) {
  const { t } = useTranslate();

  if (!(itemsCount > 0)) {
    return null;
  }

  return (
    <div className="w-full flex justify-center p-4 sticky bottom-0 z-10 pointer-events-none">
      <div className="flex items-stretch pointer-events-auto">
        <PureButtonHelper
          className="bg-blue-500 hover:bg-blue-600 text-white p-2 pl-3 xl:p-3 xl:pl-5 text-sm xl:text-base rounded-l-full shadow-lg dark:glow-lg leading-none"
          text={`${t(
            isOpen
              ? 'words.show_n_selected_items.hide'
              : 'words.show_n_selected_items.show'
          )} ${itemsCount} ${t('words.show_n_selected_items.selected_items')}`}
          onClick={isOpen ? onClose : onOpen}
        />

        <ConfirmModalButton
          className="bg-blue-500 hover:bg-blue-600 text-white p-2 pr-3 xl:p-3 xl:pr-5 text-sm xl:text-base rounded-r-full shadow-lg dark:glow-lg border-l border-blue-400 dark:border-blue-600"
          icon={IconsEnum.X}
          iconClassName="h-4 w-4 xl:h-5 xl:w-5"
          tooltipI18nText={words.clearSelection}
          tooltipPlacement={TooltipPlacement.TOP}
          i18nText={stringsKeys.areYouSureYouWantToClearSelected}
          i18nTitle={words.clearSelection}
          i18nSubmitText={words.clear}
          disabled={clearLoading}
          afterSubmit={onClear}
        />
      </div>
    </div>
  );
}

export default SelectedLibraryItemsFloatingCounter;
