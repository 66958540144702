import React, { useCallback } from 'react';

import { IconsEnum } from '../../../../assets/icons/types';

import {
  CheckboxAlphabeticalGroupIdType,
  CheckboxAlphabeticalGroupLabelType,
  CheckboxAlphabeticalGroupOnRemove
} from '../../CheckboxAlphabeticalGroup.types';

import { PureIconButtonHelper } from '../../../buttons/PureIconButtonHelper';

interface CheckboxAlphabeticalGroupSelectedItemProps {
  label: CheckboxAlphabeticalGroupLabelType;
  id: CheckboxAlphabeticalGroupIdType;
  onRemove: CheckboxAlphabeticalGroupOnRemove;
}

function CheckboxAlphabeticalGroupSelectedItem({
  label,
  id,
  onRemove
}: CheckboxAlphabeticalGroupSelectedItemProps) {
  const handleRemove = useCallback<() => void>(() => {
    onRemove(id);
  }, [id, onRemove]);

  return (
    <span className="bg-blue-500 bg-opacity-20 dark:text-gray-200 inline-flex items-center m-0.5 px-3 py-1.5 rounded-md text-gray-800 text-sm">
      {label}
      <PureIconButtonHelper
        icon={IconsEnum.CROSS}
        iconClassName="h-3 w-3"
        onClick={handleRemove}
      />
    </span>
  );
}

export default CheckboxAlphabeticalGroupSelectedItem;
