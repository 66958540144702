import { useCallback, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import take from 'lodash/take';

import {
  ClassName,
  FetchItemsFetchingNextPage,
  FetchItemsHasNextItemsPage,
  IsDisabled,
  IsFetched
} from '../../../../../types';
import { UserID } from '../../../../../main/users/usersTypes';

import { LoadingSkeleton } from '../../../../LoadingSkeleton';
import { Loading } from '../../../../Loading';
import { UsersListFieldListItem } from '../UsersListFieldListItem';
import { UsersListFieldListItemUser } from '../UsersListFieldListItem/UsersListFieldListItem.types';

import { words } from '../../../../../locales/keys';
import { PureIconButtonHelper } from '../../../../buttons/PureIconButtonHelper';
import { IconsEnum } from '../../../../../assets/icons/types';

interface UsersListPopoverFieldListProps {
  disabled?: IsDisabled;
  onRemoveUser: (id: UserID) => void;
  users: UsersListFieldListItemUser[];
  usersFetched: IsFetched;
  value: UserID[];
  usersFetchingNextPage: FetchItemsFetchingNextPage;
  hasNextUsersPage: FetchItemsHasNextItemsPage;
  handleLoadMoreUsers: () => void;
  className?: ClassName;
  listClassName?: ClassName;
}

const INITIAL_LIMIT_USERS = 6;

function UsersListPopoverFieldList({
  onRemoveUser,
  users,
  usersFetched,
  value,
  usersFetchingNextPage,
  hasNextUsersPage,
  className,
  listClassName,
  handleLoadMoreUsers
}: UsersListPopoverFieldListProps) {
  const [opened, setOpened] = useState<boolean>(true);

  const handleClickLoadMoreLess = useCallback(() => {
    if (hasNextUsersPage) {
      handleLoadMoreUsers();
    }
    if (opened && !hasNextUsersPage) {
      setOpened(false);
    } else {
      setOpened(true);
    }
  }, [handleLoadMoreUsers, hasNextUsersPage, opened]);

  if (isEmpty(value)) {
    return null;
  }

  return (
    <LoadingSkeleton count={2} loaded={usersFetched}>
      <div className={className || 'relative'}>
        <ul className={listClassName}>
          {(opened ? users : take(users, INITIAL_LIMIT_USERS))?.map((user) => (
            <UsersListFieldListItem
              key={`user-${user.nanoId}`}
              user={user}
              onRemove={onRemoveUser}
            />
          ))}
        </ul>
        {(hasNextUsersPage || !opened) && (
          <div className="absolute bg-gradient-to-b bottom-0 from-transparent h-14 inset-x-0 to-white dark:to-gray-850" />
        )}
      </div>
      {hasNextUsersPage || size(users) > INITIAL_LIMIT_USERS ? (
        <div className="flex justify-center py-2">
          <Loading loaded={!usersFetchingNextPage}>
            <PureIconButtonHelper
              i18nText={
                hasNextUsersPage ||
                (size(users) > INITIAL_LIMIT_USERS && !opened)
                  ? words.showMore
                  : words.showLess
              }
              className="py-1.5 pl-3 pr-3 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
              onClick={handleClickLoadMoreLess}
              icon={
                hasNextUsersPage ||
                (size(users) > INITIAL_LIMIT_USERS && !opened)
                  ? IconsEnum.CHEVRON_DOWN
                  : IconsEnum.CHEVRON_UP
              }
              iconClassName="h-5 w-5"
            />
          </Loading>
        </div>
      ) : null}
    </LoadingSkeleton>
  );
}

export default UsersListPopoverFieldList;
