import React from 'react';

import { IsLoading, ErrorMessage, I18nText } from '../../../types';

import { useFetchMoreInfiniteButtonHelper } from './hooks/useFetchMoreInfiniteButtonHelper';

import { Translate } from '../../Translate';
import { Loading } from '../../Loading';

import { FetchMoreInfiniteButtonHelperPlacement } from './FetchMoreInfiniteButtonHelper.types';

interface FetchMoreInfiniteButtonHelperProps {
  distanceToViewport?: number;
  errorMessage: ErrorMessage;
  isLoading?: IsLoading;
  hasNextPage?: boolean;
  i18nText: I18nText;
  className?: string;
  wrapperClassName?: string;
  placement: FetchMoreInfiniteButtonHelperPlacement;
  onFetchMore: () => void;
}

function FetchMoreInfiniteButtonHelper({
  distanceToViewport = 4000,
  errorMessage,
  isLoading,
  hasNextPage = false,
  i18nText,
  className,
  wrapperClassName,
  placement,
  onFetchMore
}: FetchMoreInfiniteButtonHelperProps) {
  const { buttonRef } = useFetchMoreInfiniteButtonHelper({
    distanceToViewport,
    isLoading,
    hasNextPage,
    errorMessage,
    placement,
    onFetchMore
  });

  if (!hasNextPage) {
    return null;
  }

  return (
    <div className={wrapperClassName}>
      <Loading loaded={!isLoading}>
        <button
          ref={buttonRef}
          className={className}
          onClick={onFetchMore}
          disabled={isLoading}
          type="button"
        >
          <Translate id={i18nText} />
        </button>
      </Loading>
    </div>
  );
}

export default FetchMoreInfiniteButtonHelper;
