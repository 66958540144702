import React, { useCallback } from 'react';
import toString from 'lodash/toString';

import { Checkbox, CheckboxProps } from '../Checkbox';

interface GroupedCheckboxValueType {
  id: string;
  checked?: boolean;
}

interface GroupedCheckboxProps extends Omit<CheckboxProps, 'onChange'> {
  onChange?: (value: GroupedCheckboxValueType) => void;
}

function GroupedCheckbox({
  checkboxWrapperClassName,
  checked = false,
  disabled,
  error,
  errorClassName,
  i18nLabel,
  id,
  checkboxClassName,
  label,
  labelClassName,
  name,
  onChange
}: GroupedCheckboxProps) {
  const handleChange = useCallback(
    (checked: boolean) => {
      onChange && onChange({ id: toString(id), checked });
    },
    [id, onChange]
  );

  return (
    <Checkbox
      checkboxClassName={checkboxClassName}
      checkboxWrapperClassName={checkboxWrapperClassName}
      checked={checked}
      disabled={disabled}
      error={error}
      errorClassName={errorClassName}
      i18nLabel={i18nLabel}
      id={id}
      label={label}
      labelClassName={labelClassName}
      name={name}
      onChange={handleChange}
    />
  );
}

export default GroupedCheckbox;
