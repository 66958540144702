import { useMemo } from 'react';

import { useTranslate } from '../../../../../../../common/hooks/useTranslate';

import { MultiSelectDataType } from '../../../../../../../helpers/MultiSelect/types';

import { projectsKeys } from '../../../../../../../locales/keys';

const SELECTED_TASK_EMPTY_VALUE_VALUE = 'attach_directly_to_project' as const;

function useSelectProjectAndTaskFormSelectedTaskEmptyValue() {
  const { t } = useTranslate();

  const selectedTaskEmptyValue = useMemo<MultiSelectDataType>(() => {
    return {
      value: SELECTED_TASK_EMPTY_VALUE_VALUE,
      label: t(projectsKeys.attachDirectlyToProject)
    };
  }, [t]);

  return selectedTaskEmptyValue;
}

export default useSelectProjectAndTaskFormSelectedTaskEmptyValue;
