import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

import { IconsEnum } from '../../../../assets/icons/types';

import {
  CheckboxAlphabeticalGroupI18nSearchPlaceholder,
  CheckboxAlphabeticalGroupSearchValue,
  CheckboxAlphabeticalGroupOnSearch
} from '../../CheckboxAlphabeticalGroup.types';

import { useTranslate } from '../../../../common/hooks/useTranslate';

import { Icon } from '../../../Icon';

interface CheckboxAlphabeticalGroupSearchProps {
  i18nSearchPlaceholder?: CheckboxAlphabeticalGroupI18nSearchPlaceholder;
  searchValue?: CheckboxAlphabeticalGroupSearchValue;
  onSearch: CheckboxAlphabeticalGroupOnSearch;
}

function CheckboxAlphabeticalGroupSearch({
  i18nSearchPlaceholder,
  searchValue,
  onSearch
}: CheckboxAlphabeticalGroupSearchProps) {
  const [updatedValue, setUpdatedValue] = useState<string>(searchValue || '');

  const debouncedOnSearch = useCallback<(input: string) => void>(
    debounce((input) => {
      onSearch(input);
    }, 500),
    [onSearch]
  );

  const handleChange = useCallback<(e: ChangeEvent<HTMLInputElement>) => void>(
    (e) => {
      const input = e?.target?.value || '';
      setUpdatedValue(input);
      debouncedOnSearch(input);
    },
    [debouncedOnSearch]
  );

  useEffect(() => {
    if (!searchValue && updatedValue) {
      setUpdatedValue('');
    }
  }, [searchValue]);

  const { t } = useTranslate();

  return (
    <div className="-mx-2">
      <div className="">
        <div className="relative">
          <div className="absolute flex inset-y-0 items-center left-0 ml-3 pointer-events-none">
            <Icon icon={IconsEnum.SEARCH} />
          </div>
          <input
            type="text"
            value={updatedValue}
            placeholder={
              i18nSearchPlaceholder ? t(i18nSearchPlaceholder) : undefined
            }
            className="basic-input pl-10"
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}

export default CheckboxAlphabeticalGroupSearch;
