import { ID } from '../../../../../../../types';

import { generateUuid } from '../../../../../../../utils/generateUuid';
import { getQueryName } from '../../../../../../../utils/getQueryName';
import { productionConsole } from '../../../../../../../utils/productionConsole';

import { graphQLClient } from '../../graphQLClients';

interface FetchItemsSetByUserIdProps {
  query: string;
  userId: ID;
  scope?: string;
}

export function fetchItemsByUserId({
  query,
  userId,
  scope
}: FetchItemsSetByUserIdProps) {
  const { rawName, name } = getQueryName(query);

  productionConsole(
    'log',
    'fetchItemsByUserId',
    { userId, scope },
    rawName,
    JSON.stringify({ userId, scope })
  );

  const requestId = generateUuid();

  graphQLClient.addQueryParams(name, requestId);

  return graphQLClient.request(query, { userId, scope }, { requestId });
}
