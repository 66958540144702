import { I18nText } from '../../types';

export enum YesNoAnyRadioButtonsValue {
  YES = 'YES',
  NO = 'NO',
  ANY = 'ANY'
}

export type YesNoAnyRadioButtonsItemI18nText = I18nText;

export type YesNoAnyRadioButtonsOnChange = (
  buttonValue: YesNoAnyRadioButtonsValue
) => void;
