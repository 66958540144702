import React, { useMemo, useCallback, Fragment } from 'react';
import compact from 'lodash/compact';
import concat from 'lodash/concat';

import {
  MultiSelectDataType,
  MultiSelectInputChangeCallbackType
} from '../../../../helpers/MultiSelect/types';
import { ProjectUuidsSelectFieldControlProps } from './ProjectUuidsSelectField.types';

import {
  FETCH_SELECT_FIELD_PROJECTS_QUERY,
  FetchSelectFieldProjectsQueryResponse
} from '../../queries/fetchSelectFieldProjects.query';

import { useProjects } from '../../hooks/useProjects';

import {
  MultiSelectField,
  MultiSelectFieldProps
} from '../../../../helpers/FormFields/MultiSelectField';
import { AlertMessage } from '../../../../helpers/AlertMessage';

import { ProjectCache } from '../../ProjectCache';

function ProjectUuidsSelectField<T>({
  control,
  name,
  i18nPlaceholder,
  inputWrapperClassName,
  i18nLabel,
  labelClassName,
  disabled,
  defaultValue,
  initialFilters
}: ProjectUuidsSelectFieldControlProps<T> & MultiSelectFieldProps<T>) {
  const {
    projects,
    projectsError,
    projectsLoading,
    changeProjectsFilters,
    loadMoreProjects,
    projectsFetchingNextPage
  } = useProjects<FetchSelectFieldProjectsQueryResponse>({
    cacheKey: ProjectCache.selectFieldCacheKey(),
    query: FETCH_SELECT_FIELD_PROJECTS_QUERY,
    initialFilters
  });

  const projectUuidsSelectData = useMemo<MultiSelectDataType[]>(() => {
    return projects.map((project) => ({
      label: project.name,
      value: project.uuid
    }));
  }, [projects]);

  const projectUuidsSelectDataWithDefaultValue = compact(
    concat(defaultValue, projectUuidsSelectData)
  );

  const handleInputChange = useCallback<MultiSelectInputChangeCallbackType>(
    (input) => {
      changeProjectsFilters(
        { name: { ilike: input } },
        compact([input ? null : 'name'])
      );
    },
    [changeProjectsFilters]
  );

  return (
    <Fragment>
      <MultiSelectField<T>
        control={control}
        name={name}
        data={projectUuidsSelectDataWithDefaultValue}
        i18nPlaceholder={i18nPlaceholder}
        inputWrapperClassName={inputWrapperClassName}
        i18nLabel={i18nLabel}
        labelClassName={labelClassName}
        disabled={disabled}
        optionsLoading={projectsLoading}
        classNamePrefix="av"
        multi={false}
        menuPosition="fixed"
        isSearchable
        onInputChange={handleInputChange}
        onMenuScrollToBottom={loadMoreProjects}
        isLoading={projectsFetchingNextPage}
      />
      <AlertMessage addClassName="mt-2" message={projectsError} />
    </Fragment>
  );
}

export default ProjectUuidsSelectField;
