import React from 'react';
import cl from 'classnames';
import find from 'lodash/find';
import upperCase from 'lodash/upperCase';

import {
  CheckboxAlphabeticalGroupIdType,
  CheckboxAlphabeticalGroupLabelType,
  CheckboxAlphabeticalGroupOnItemChange
} from '../../CheckboxAlphabeticalGroup.types';

import { GroupedCheckbox } from '../../../GroupedCheckbox';

function isLetter(char: string): boolean {
  return /^[A-ZА-ЯЁҐЄІЇ]/i.test(char);
}

function isNum(char: string): boolean {
  return /^[0-9]/i.test(char);
}

function findFirstSortableChar(label?: string): string | undefined {
  return find(label, (char) => isLetter(char) || isNum(char));
}

interface CheckboxAlphabeticalGroupItemProps {
  name: string;
  id: CheckboxAlphabeticalGroupIdType;
  label: CheckboxAlphabeticalGroupLabelType;
  prevItemLabel?: CheckboxAlphabeticalGroupLabelType;
  first: boolean;
  checked: boolean;
  disabled?: boolean;
  onChange: CheckboxAlphabeticalGroupOnItemChange;
}

function CheckboxAlphabeticalGroupItem({
  name,
  id,
  label,
  prevItemLabel,
  first,
  checked,
  disabled,
  onChange
}: CheckboxAlphabeticalGroupItemProps) {
  const firstSortableChar = upperCase(findFirstSortableChar(label));
  const prevFirstSortableChar = upperCase(findFirstSortableChar(prevItemLabel));

  const isNewSortableChar =
    !!firstSortableChar && firstSortableChar !== prevFirstSortableChar;

  return (
    <>
      {firstSortableChar &&
      (first || isNewSortableChar) &&
      isLetter(firstSortableChar) ? (
        <li className={cl('pb-2', { 'pt-4': !first })}>
          <span className="font-medium">{firstSortableChar}</span>
        </li>
      ) : null}

      <li className="my-1.5">
        <GroupedCheckbox
          name={name}
          checkboxWrapperClassName="flex items-center"
          checked={checked}
          disabled={disabled}
          id={id}
          label={label}
          onChange={onChange}
        />
      </li>
    </>
  );
}

export default CheckboxAlphabeticalGroupItem;
