import { gql } from 'graphql-request';

export const RECREATE_IMAGE_VERSIONS_QUERY = gql`
  mutation RecreateImageVersions(
    $uuid: ID!
    $versions: [String!]
    $async: Boolean
  ) {
    recreateImageVersions(
      input: { uuid: $uuid, versions: $versions, async: $async }
    ) {
      status
      errors {
        fullMessages
      }
    }
  }
`;
