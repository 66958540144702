import { MultiSelectDataType } from '../../../../../helpers/MultiSelect/types';

import { ProjectUUID } from '../../../../projects/projectsTypes';
import { TaskNanoID, TaskUUID } from '../../../../tasks/tasksTypes';

export interface SelectProjectAndTaskFormData {
  selectedProject: MultiSelectDataType | null;
  selectedTask: MultiSelectDataType | null;
}

export type SelectProjectAndTaskFormOnSubmitAction = (value: {
  projectUuid: ProjectUUID;
  taskUuid: TaskUUID | TaskNanoID | null;
}) => Promise<unknown>;

export enum SelectProjectAndTaskFormFields {
  SELECTED_PROJECT = 'selectedProject',
  SELECTED_TASK = 'selectedTask'
}
